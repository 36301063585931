.header {
  // background-color: #ffffff;
  width: 100%;
  // border-radius: 0 0 1rem 1rem;
  //   direction: ltr;
}

.back {
  width: 60px;
  height: 20px;
}
.logoContainer,
.profileContainer {
  height: theme("height.header");
  margin: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.profileContainer {
  width: fit-content;
}

.profileContainer {
  width: theme("height.header");
}
.logo {
  width: 5px;
  height: 25px;
  max-height: calc(theme("height.header") - 8px);
  cursor: pointer;
}

.profile {
  width: calc(theme("height.header") - 10px);
  height: auto;
  cursor: pointer;
}

.logoOrange {
  position: absolute;
  top: 23px;
  left: calc(50% - 35px);
  img {
    width: 70px;
    height: 70px;
  }
}
