.PackCardContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: 16px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 20px;
  flex-wrap: wrap;
  border-radius: 10px;
  background: #ffefea;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.packTray {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  position: relative;
  margin-top: 8px;
  margin-bottom: 8px;

  .tray {
    width: 45px;
    height: 45px;
    position: relative;
    object-fit: cover;
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow-y: auto;
    background-repeat: no-repeat;
    scrollbar-width: thin;
    scrollbar-color: #ccc transparent;
    background-image: url("../../../public/frame122.svg");
    background-size: contain;
    background-position: center;
    min-height: 185px;
    
  }
  .max{
    position: relative;
    height: 85%;
    width: 80%;
    max-height:140px;
    overflow-y: auto;
  }
  
}

.contentNames {
  padding-left: 8px;
  color: #393939;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  direction: ltr;
  height: auto;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #ccc transparent;
  margin-bottom: 8px;
}

.price {
  color: #231f20;
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
}
