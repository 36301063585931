// div with class root is in index.html
.root {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100vw;
  height: 100dvh;
  overflow: hidden;
}

.ltr {
  direction: ltr;
}

.rtl {
  direction: rtl;
}

.dir-by-lang {
  &:lang(en) {
    direction: ltr !important;
  }
  &:lang(fa),
  &:lang(ar) {
    direction: rtl !important;
  }
}

.box-shadow-primary {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
}

.validation-error-message {
  color: theme("colors.validationError.default");
  font-size: 12px;
  width: 100%;

  text-align: start;
}

.image-container-slider-banner {
  width: 320px;
  height: auto;
  max-width: 100%;
  overflow: hidden;
  border-radius: 7px;
  .image-slider-banner {
    width: 100%;
    height: auto;
  }
}
.all-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fr-card-wrapper {
  box-shadow: 0 5px 21px 0 #f0f0f0;
  border-radius: 0.8rem;
  background-color: theme("colors.green.600");
  padding: 5px 8px;
}
.fr-primary-button {
  box-shadow: 0 5px 21px 0 #f0f0f0;
  border-radius: 0.4rem;
  background-color: theme("colors.green.600");
  padding: 5px 8px;
  cursor: pointer;
}
.fr-secondry-button {
  // box-shadow: 0 5px 21px 0 #f0f0f0;
  border-radius: 0.4rem;
  background-color: inherit;
  padding: 5px 8px;
  cursor: pointer;
  color: #929292;
}
.fr-orange-button {
  box-shadow: 0 5px 21px 0 #f0f0f0;
  border-radius: 0.4rem;
  background-color: theme("colors.white.backGround");
  border: 1px dashed theme("colors.orange.900");
  padding: 5px 8px;
  cursor: pointer;
}

.fr-underline {
  position: absolute;
  border-bottom: 2px solid theme("colors.orange.default");
  width: 30%;
  left: 35%;
  bottom: -5px;
  // right: 35%;

  &::before,
  &::after {
    position: absolute;
    content: "";
    width: 50%;
    // margin: 0 30px;
    border-bottom: 2px dashed theme("colors.orange.default");
  }

  &::before {
    left: 105%;
  }
  &::after {
    left: -55%;
  }
}
// start customizing swiper slider
// start swiper height full of parent

.mySwiper.full-height-slider {
  .swiper-slide {
    height: 100%;
  }
}

// custom-button-slider-landingPage
.custom-button-slider-landingPage {
  .swiper-button-next,
  .swiper-button-prev {
    top: 73% !important;
    &::after {
      font-size: 20px;
      font-weight: 900;
      color: #87cb44;
    }
  }
}
.custom-button-slider-show-nut-details {
  .swiper-button-next,
  .swiper-button-prev {
    top: 50% !important;
    &::after {
      font-size: 20px;
      font-weight: 900;
      color: #87cb44;
    }
  }
}

// end of swiper height full of parent
// custom-button-slider-productDetails
.custom-button-slider-productDetails {
  position: relative;
  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 30px;
    &::after {
      font-size: 16px;
      font-weight: 900;
      color: theme("colors.orange.default");
      border: 1px solid #000000d7;
      background-color: #000000a1;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      padding: 6px 10px;
    }
  }
  .swiper-button-prev {
    padding: 5px 9px;
  }
}

div {
  // === remove background color on touch div
  -webkit-tap-highlight-color: transparent;
  // === disable text selection of an element iّn web browsers
  user-select: none;
}

.width-auto-swiper-slide {
  .swiper-slide {
    width: fit-content !important;
  }
}
// leaflet => start map customize
.leaflet-container {
  width: 100%;
  height: 100%;
}
// leaflet => end of map customize

/***** start  Hide scrollbar *****/
/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/***** end  Hide scrollbar *****/

// start swiper slider

.swiper {
  width: 100%;
  // height: 100%;
  height: auto;
}
.swiper-slide {
  text-align: center;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-slide {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swipper-width-auto {
  .swiper-slide {
    width: auto;
  }
}
// end swiper slider

.primary-link {
  display: flex;
  padding: 5px 7px;
  font-size: 16px;
  background-color: rgb(43, 207, 43);
  border-radius: 7px;
}

.primary-btn {
  display: flex;
  padding: 3px 5px;
  // margin: 3px 5px;
  background-color: rgb(43, 207, 43);
  border-radius: 7px;
}

/* ***** start checkBox input animation *****/
// round slider
.switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 18px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 19px;
  width: 19px;
  left: 0px;
  bottom: 0px;
  background-color: theme("colors.orange.default");
  transition: 0.4s;
}

input:checked + .slider {
  background-color: theme("colors.orange.default");
  // background-color: #2196F3;
}

input:checked + .slider::before {
  background-color: #ccc;
}

input:focus + .slider {
  // box-shadow: 0 0 1px #2196F3;
  box-shadow: 0 0 1px theme("colors.orange.default");
}

input:checked + .slider:before {
  transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* ***** end checkBox input animation *****/

/* in touch device such as mobile, tablet
@media (hover: none) */

/* in desktop (mouse), touch pad
@media (hover: hover) */

/* smartphones, touchscreens
@media (hover: none) and (pointer: coarse)  */

/* stylus-based screens
@media (hover: none) and (pointer: fine) */

/* Nintendo Wii controller, Microsoft Kinect 
@media (hover: hover) and (pointer: coarse) */

/* mouse, touch pad 
 @media (hover: hover) and (pointer: fine) */
