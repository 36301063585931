.container {
  //   margin-top: 2rem;
  //   width: 90%;
  //   max-width: 200px;
  //   height: 100px;
  box-shadow: 0 4px 4px 0 hsla(0, 0%, 0%, 25%);
  border-radius: 10px;
  //   background-color: theme("colors.green.500");
  transition: all ease 0.3s;
  cursor: pointer;
  h2 {
    color: #393939;
    font-weight: medium;
    font-size: 21px;
  }

  .img {
    display: block;
  }
}
