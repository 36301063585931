.yellowButton {
  display: flex;
  padding: 12px 0px 11px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #fbbb23;
  color: #393939;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
