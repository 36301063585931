.container {
  width: 100vw;
  height: 100vh;
  background-size: cover;

  display: flex;
  justify-content: center;
  align-items: center;

  .wrapper {
    position: relative;
    background-color: #fff8f6;
    border-radius: 20px;
    width: 290px;
    min-height: 320px;
    .fereziLogo {
      position: absolute;
      top: -165px;
      width: 160px;
      position: absolute;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    }

    @media (min-width: 380px) {
      width: 310px;
      // height: 320px;
      min-height: 400px;
      height: auto;
    }
    @media (min-width: 410px) {
      width: 330px;
      // height: 370px;
    }

    @media (min-width: 480px) {
      width: 400px;
    }
  }
}
.bg-image-general {
  background-image: url("../../assets/images/landing/landing-background.png");
}
.bg-image-login {
  background-image: url("../../assets/images/landing/landing-background.png");
}
